footer {
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    bottom: 0;
    font-family: "Arimo";
    letter-spacing: 0.11rem;
  }
  footer .backToTop {
    margin: 50px auto;
    /* margin-bottom: 20%; */
  }
  
  footer .email {
    /* font-size: 2rem; */
    font-weight: 500;
    margin-top: 2rem;
  }
  footer .footerCredits {
    font-size: 0.875rem;
  }
  
  footer a {
    /* font-size: 2rem; */
    padding-bottom: 2px;
    border-bottom: 1px solid;
    margin-right: 3px;
  }
  
  footer a:nth-of-type(2),
  footer a:nth-of-type(3) {
    padding-bottom: 2px;
    border-bottom: 1px solid;
    margin-left: 3px;
  }
  
  footer .linkDivider {
    /* font-size: 2rem; */
  }


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    footer a,
    footer .email,
    footer .linkDivider {
      font-size: 1rem;
    }
  
    
  }
  