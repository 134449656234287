body.mobile-navigation-open {
  overflow: hidden;
}

::selection {
  background-color: pink;
  color: white; 
}

.headerContainer {
  width: 100%;
  
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.brandContainer {
  width: "40%";
}

.brandName {
  width: "100%";
}
.brandName a {
  font-family: "Abhaya Libre", Times, Georgia, serif;
  font-weight: 400;
  text-align: center;
}

.navigation {
  display: flex;
  flex-direction: row;
  /* position: relative; */
}

.list {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
}

.list li {
  padding: 8px;
}

.list li a {
  color: black;
  /* color: #9c9c9c; */
  text-decoration: none;
  font-family: "Abhaya Libre";
  font-size: 1rem;
  font-weight: 400;
   /*text-transform: uppercase; */
  letter-spacing: 0.11rem;
}
.list li a:hover,
.list li a:active {
  color: black;
  text-decoration: none;
}

.hamburgerContainer {
  z-index: 100;
}

.mobileNavigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: 100vh; */
  background-color: rgba(255, 255, 255, 0.90);
  flex:1;
  align-items: center;
  display: flex;
  z-index: 10;
}
.mobileList {
  display: flex;
  flex:1;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  /* height: 20%;
  margin-top: 40%; */
}

.mobileList li {
  display: flex;
  align-items: "center";
  padding: 14px;
}

.mobileList li a {
  color: black;
  /* color: #9c9c9c; */
  text-decoration: none;
  font-family: "Abhaya Libre";
  font-size: 1.5 rem;
  font-weight: 400;
  /* text-transform: uppercase; */
  letter-spacing: 0.11rem;
}
.mobileList li a:hover,
.mobileList li a:active {
  color: black;
  text-decoration: none;
}

.activeListItem {
}

.activeText {
  padding-bottom: 8px;
  border-bottom: 2px solid;
}

.mobileList a {
}



@media only screen and (max-width: 768px) {

  .headerContainer {
  }
    .mobileNavigation {
      align-items: flex-start;
      
    }
        .mobileList{
          margin-top: 200px
        }
}
