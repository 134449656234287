@import url("https://fonts.googleapis.com/css2?family=Raleway&family=Abhaya+Libre:wght@400;700&family=Arimo&family=Roboto+Mono&family=Forum&display=swap");

* {
  padding: 0;
  box-sizing: border-box;
  justify-content: space-around;
}

body {
  font-family: "Arimo", "Helvetica", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Arial", sans-serif;
  /* margin: 0 auto;
  padding: 0 7vw; */
  font-size: 14px;
  font-weight: 300;
}

p {
  font-family: "Arimo", "Times New Roman", "Georgia",serif;
  font-size: 1em;
  line-height: 2em;
  font-weight: 400;
  color:rgba(0, 0, 0, 0.8);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Abhaya Libre", 'Times New Roman', Times, Georgia, serif;
  font-weight: 400;
  letter-spacing: 2px;
  color:rgba(0, 0, 0, 0.7); 
}

.headingIntro{    
  font-family: 'Raleway';
  letter-spacing: 2.24px;
  font-weight: 100;
  text-transform:uppercase;  
  line-height: 1rem;
  }

.subtitle {
  font-family:  'Abhaya Libre', Times,  Georgia, serif;
  font-size: 1rem;
  letter-spacing: 0.07em;
  word-spacing: 0.11em;
  line-height: 1.25em;
  text-transform: none;
  font-weight: normal;
  font-style: italic;
  color: #9c9c9c;
}

.bottomText{
    font-family: 'Roboto Mono', Times, Georgia, serif;
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: 0.07em;
    word-spacing: 0.11em;
    line-height: 1.25em;
    /* text-transform: uppercase; */
    font-weight: normal;
    /* font-style: italic; */
    color: #9c9c9c;
}

.highlightedParagraph{
  font-weight: 400;
  font-style: italic;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 40px;
}

a {
  color: black;
  text-decoration: none;
}

.homeBigText {
  text-align: center;
  font-family: "Abhaya Libre";
  font-style: italic;
  font-size: 3rem;
  margin: 0;
      line-height: 1em;

}


button {
    background-color: pink;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 0.854em;
    letter-spacing: 1.24px;
    transition: background-color 0.3s;
    font-family: 'Arimo', sans-serif;    
    text-transform: uppercase;
  
  }
  
  button:hover {
   /* background-color: rgb(255, 63, 140);*/
  }

  .paddingHorizontal {padding: 0 5vw}